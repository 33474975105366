<template>
    <div class="real-friend-meeting-refund-reject">
        <StackRouterHeaderBar />

        <h1 class="title f-24 m-t-18 m-l-16">귀책 사유 작성</h1>

        <section class="section">
            <div class="m-b-20 f-14">
                <p>왜 친구분이 <span class="f-bold">만남을 가지지 않았는지</span> 작성해주세요!</p>
                <p class="f-medium c-primary">(작성한 내용은 회원에게 공개되지 않습니다.)</p>
            </div>

            <TextareaWithX
                class="textarea"
                placeholder="왜 친구분이 만남을 가지지 않았는지 작성해주세요"
                v-model="description"
            />
        </section>

        <BottomButton label="제출하기" @click="onClick" :disabled="description.length === 0" />
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar.vue'
import TextareaWithX from '@/components/app/TextareaWithX.vue'
import realFriendService from '@/services/realfriend-meeting'

export default {
    name: 'RealFriendMeetingRefundCheckPage',
    components: { StackRouterHeaderBar, TextareaWithX },
    props: {
        introduce: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        description: '',
    }),
    methods: {
        async onClick() {
            try {
                this.$loading(true)
                const idx = await this.$modal.basic({
                    body: '작성 내용을 제출합니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })

                if (idx === 1) {
                    const { msg } = await realFriendService.confirmRefund(
                        this.introduce.id,
                        this.introduce.request.id,
                        {
                            refund_status: 2,
                            refuse_description: this.description,
                        },
                    )

                    const chat = { ...this.$store.getters.chat }
                    chat.introduce.request.accept_status = 'refund_reject'
                    this.$store.commit('updateChatAndChats', chat)

                    this.$toast.success(msg)
                    this.$stackRouter.clear()
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.real-friend-meeting-refund-reject {
    .title {
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .section {
        padding: 40px 16px;
    }

    .textarea {
        height: 320px;
    }

    ::v-deep .bottom-button {
        position: absolute;
    }
}
</style>
